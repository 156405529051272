import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'

import {useEffect}          from 'react'

import React                from 'react'
import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Container            from 'react-bootstrap/Container';

import AdminPageLoader      from '../../components/AdminPageLoader';
import AdminHeader          from '../../components/AdminHeader';


const AdminAbout = (props) =>{
    // -------------
    // State
    // -------------

    useEffect(() => {
        
    },[]);

    // -------------
    // User Interactions
    // -------------

    // -------------
    // Render
    // -------------

    const renderV110 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.1.0</b></h6>
                    <p className ='text-left'>- Added About page</p>                
                    <p className ='text-left'>- Change the style of the feature table</p>                
                    <p className ='text-left'>- The feature table will display the option selected by the user (if this information is available in the database)</p>
                    <p className ='text-left'>- <span style={{color:'#3171b9'}}><b>The default option</b></span> will be displayed using <span style={{color:'#3171b9'}}><b>blue</b></span> color </p>
                    <p className ='text-left'>- <span style={{color:'purple'}}><b>The option selected by the user</b></span> will be displayed using <span style={{color:'purple'}}><b>purple</b></span> color </p>                    
                </Col>
            </Row>
        )
    }

    const renderV120 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.2.0</b></h6>
                    <p className ='text-left'>- Now supporting Excel and XML files for firmware feature descriptor</p>                                    
                </Col>
            </Row>
        )
    }
    
    const renderV121 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.2.1</b></h6>
                    <p className ='text-left'>- Fix an issue with the Dropdown controller in the firmware edit page</p>                                    
                    <p className ='text-left'>- Change the API for PasswordRecoverWeb and RegisterWeb to use the Domain parameter (set as origin)</p>                                    
                </Col>
            </Row>
        )
    }
    const renderV130 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.3.0</b></h6>
                    <p className ='text-left'>- Implement the FSF file decoder</p>                                    
                    <p className ='text-left'>- Show the API version in the footbar</p> 
                    <p className ='text-left'>- Show an warning message when the user selected options cannot be displayed (Device page)</p>                                                        
                </Col>
            </Row>
        )
    }

    const renderV131 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.3.1</b></h6>
                    <p className ='text-left'>- Fix an issue with the FSF file decoder (XML Special Character Handling)</p>                                                        
                </Col>
            </Row>
        )
    }

    const renderV140 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.4.0</b></h6>
                    <p className ='text-left'>- Only an Admin user can change the role of other Admin user</p>                                                        
                    <p className ='text-left'>- Added new functionality for device management : add/delete authorized users , delete device owner</p>                                                        
                </Col>
            </Row>
        )
    }

    const renderV141 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.4.1</b></h6>
                    <p className ='text-left'>- The BootUUID field from Create new product form is optional</p>                                                                            
                </Col>
            </Row>
        )
    }

    const renderV142 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.4.2</b></h6>
                    <p className ='text-left'>- Fix an issue with the FSF file decoder (FSF files with only one bank)</p>                                                                            
                </Col>
            </Row>
        )
    }

    const renderV143 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.4.3</b></h6>
                    <p className ='text-left'>- Update the AdminDevices and AdminDevice page to proper decode the backend answer(API 1.00.06) for authorized users (the answer contains the email and user name)</p>                                                                            
                </Col>
            </Row>
        )
    }

    const renderV150 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.5.0</b></h6>
                    <p className ='text-left'>- Add support for Manufacturer Role</p>                                                                            
                    <p className ='text-left'>- Add support for serial number labelling</p>                                                                            
                </Col>
            </Row>
        )
    }

    const renderV151 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.5.1</b></h6>
                    <p className ='text-left'>- Remove the logs from production build</p>                                                                                                
                </Col>
            </Row>
        )
    }

    const renderV160 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.6.0</b></h6>
                    <p className ='text-left'>- Add reload feature</p>                                                                                                
                    <p className ='text-left'>- Fix some issues with the pages display in 1280x1024 resolution</p>                                                                                                
                </Col>
            </Row>
        )
    }

    const renderV170 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.7.0</b></h6>
                    <p className ='text-left'>- Fix a reload issue in device page</p>                                                                                                
                </Col>
            </Row>
        )
    }

    const renderV180 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.8.0</b></h6>
                    <p className ='text-left'>- Move the API to Omega AWS account</p>                                                                                                
                </Col>
            </Row>
        )
    }

    const renderV190 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.9.0</b></h6>
                    <p className ='text-left'>- Remove the Content-Type from PUT headers because is breaking the signature check from AWS S3</p>                                                                                                
                </Col>
            </Row>
        )
    }
    const renderV1100 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.10.0</b></h6>
                    <p className ='text-left'>- Set the Content-Type from PUT headers to empty because is breaking the signature check from AWS S3</p>                                                                                                
                    <p className ='text-left'>- The patch implemented in the version 1.9.0 (removing from header)dosen't work  when we upload a second file</p>                                                                                                
                </Col>
            </Row>
        )
    }

    const renderV1110 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.11.0</b></h6>
                    <p className ='text-left'>- Remove from login page the 'Create new account' and 'Forget password' links</p>                                                                                                                    
                </Col>
            </Row>
        )
    }

    const renderV1120 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.12.0</b></h6>
                    <p className ='text-left'>- ‘Technical Staff’ role permissions</p>                                                                                                                    
                </Col>
            </Row>
        )
    }

    const renderV1130 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.13.0</b></h6>
                    <p className ='text-left'>- ‘Technical Staff’ role permissions(firmware access)</p>                                                                                                                    
                </Col>
            </Row>
        )
    }

    const renderV1140 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.14.0</b></h6>
                    <p className ='text-left'>- ‘Technical Staff’ role permissions(device label)</p>                                                                                                                    
                </Col>
            </Row>
        )
    }

    const renderV1150 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.15.0</b></h6>
                    <p className ='text-left'>- Fix an issue with the barcode caching</p>                                                                                                                    
                </Col>
            </Row>
        )
    }

    const renderV1160 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.16.0</b></h6>
                    <p className ='text-left'>- Bug fix : user list and device list are not loaded when we scroll down the list</p>
                    <p className ='text-left'>- Bug fix : Search feature from Devices and Users works only in cached data.Now it works on backend database also.</p>                                                                                                                    
                </Col>
            </Row>
        )
    }

    const renderV1170 = ()=>{
        return (
            <Row style = {{marginTop:'20px'}}>
                <Col>
                    <h6 className='text-left' style={{paddingBottom:'20px'}} ><b>Version 1.17.0</b></h6>
                    <p className ='text-left'>- Bug fix : Search feature from Devices and Users not using backend database if at least one item is found in the cached database</p>                                                                                                                    
                </Col>
            </Row>
        )
    }

    const renderPage = ()=>{
        return (
            <Container fluid>
                {renderV1170()}
                {renderV1160()}
                {renderV1150()}
                {renderV1140()}
                {renderV1130()}
                {renderV1120()}
                {renderV1110()}
                {renderV1100()}                
                {renderV190()}
                {renderV180()}
                {renderV170()}
                {renderV160()}
                {renderV151()}
                {renderV150()}
                {renderV143()}
                {renderV142()}
                {renderV141()}
                {renderV140()}
                {renderV131()}
                {renderV130()}
                {renderV121()}
                {renderV120()}
                {renderV110()}
            </Container>
        )
    }




    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title='>    [ A B O U T ]' placeholder='Search brand'/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={false} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminAbout;