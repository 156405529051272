import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/table.style.css'

import {useState}           from 'react'
import {useEffect}          from 'react'
import {useRef   }          from 'react';
import {toast}              from 'react-toastify';
import {useHistory}         from 'react-router-dom'
import {useCookies}         from 'react-cookie'

import React                from 'react'
import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Container            from 'react-bootstrap/Container'
import Dropdown             from 'react-bootstrap/Dropdown'

import AdminPageLoader      from '../../components/AdminPageLoader';
import AdminHeader          from '../../components/AdminHeader';

import Loader               from "react-loader-spinner";

const GetUsersAPI  = require('../../models/api/admin/GetUsers');
const GetDeviceInfo= require('../../models/api/device/GetInfo')
const UpdateUserAPI= require('../../models/api/admin/UpdateUser');
const Logger       = require('../../models/helpers/ConsoleHelper');

var LastKey     ;
var UserInfo    ;
var serverItems ;


const AdminUsers = (props) =>{
    // -------------
    // State
    // -------------
    const tableREF = useRef();

    const [visibleItems   , setVisibleItems   ] = useState(null);    
    const [SearchFilter   , setSearchFilter   ] = useState('');
    
    const [showPageLoader , setShowPageLoader ] = useState(true );
    const [showTableLoader, setShowTableLoader] = useState(false);
    const [cookie] = useCookies();
    const history  = useHistory();


    useEffect(() => {
        UserInfo = cookie['UserInfo' ];

        setSearchFilter('');

        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
        LastKey     = 'begin';
        serverItems = null;

        if(userInfoValid === true){
            loadUsersFromDatabase(true,'');
        }else{
            history.push("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // -------------
    // User Interactions
    // -------------

    const reloadPage = ()=>{
        UserInfo = cookie['UserInfo' ];

        setSearchFilter('');

        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
        LastKey = 'begin';

        if(userInfoValid === true){
            loadUsersFromDatabase(true,'');
        }else{
            history.push("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const onUserRoleChanged = (user,role)=>{
        var Params = {};

        Params['Role'] = role;

        var admin = cookie['UserInfo' ];

        if(admin.Role !== 'Admin'){
            toast.error('Only athe administrator can modify the user role')
            return;
        }


        setShowTableLoader(true);            

        UpdateUserAPI.InvokeSync(admin.LoginToken,user.Email,JSON.stringify(Params),(response,error)=>{
            
            setShowTableLoader(false);            

            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    toast.success('User role updated',{toastId: 0});  

                    updateUserRole(user,role)
                }
            }                        
        });
    }

    const onSearchChanged = (text)=>{

        var deleteText = SearchFilter.length >= text.length;

        setSearchFilter(text);

        var filteredUsers = filterUsers(serverItems,text);

        if(deleteText === false){
            LastKey='begin';
            loadUsersFromDatabase(false,text);    
        }else{
            Logger.log('Delete text')
        }
    }

    const onUserClick = (user)=>{
        history.push(
            {
                pathname:"/administrator/users/user",
                state   :{user:user}
            });
    }

    const onDeviceUUIDClick = (deviceUUID)=>{
        Logger.log('onDeviceUUID click',deviceUUID);

        var admin = cookie['UserInfo' ];

        setShowTableLoader(true);

        GetDeviceInfo.InvokeSync(admin.LoginToken,deviceUUID,(response,error)=>{
            setShowTableLoader(false);

            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var device  = JSON.parse(response['param'])

                    var DeviceSettings          = {};
                    var LinkedProdctSettings    = [];
                    var AuthorizedUsers         = [];

                    DeviceSettings['aux1Enable' ] = 'false';
                    DeviceSettings['aux2Enable' ] = 'false';
                    DeviceSettings['aux3Enable' ] = 'false';
                    DeviceSettings['aux4Enable' ] = 'false';
                    DeviceSettings['aux5Enable' ] = 'false';
                    DeviceSettings['trunkEnable'] = 'false';
                    
                    if(device.DeviceSettings === 'empty'){
                        device.DeviceSettings = DeviceSettings;
                    }else{
                        try{
                            device.DeviceSettings = JSON.parse(device.DeviceSettings);
                        }catch(e){device.DeviceSettings = DeviceSettings}
                    }
                    
                    if(device.LinkedProdctSettings === 'empty'){
                        device.LinkedProdctSettings = LinkedProdctSettings;
                    }else{                            
                        try{
                            device.LinkedProdctSettings = JSON.parse(device.LinkedProdctSettings);
                        }catch(e){device.LinkedProdctSettings=LinkedProdctSettings}
                    }
                    
                    if(device.AuthorizedUsers === 'empty'){
                        device.AuthorizedUsers = AuthorizedUsers;
                    }else{                            
                        try{
                            device.AuthorizedUsers = JSON.parse(device.AuthorizedUsers);
                        }catch(e){device.AuthorizedUsers=AuthorizedUsers}
                    }                     

                    history.push(
                    {
                        pathname:"/administrator/devices/device",
                        state   :{device:device}
                    });
                }
            }
        })

    }

    const onScroll = () => {
        
        if (tableREF.current) {
          
            const { scrollTop, scrollHeight, clientHeight } = tableREF.current;

            Logger.log('scrollTop '   ,scrollTop);
            Logger.log('scrollHeight ',scrollHeight);
            Logger.log('clientHeight ',clientHeight);
            
            if ((scrollTop + clientHeight) > (scrollHeight-10)) {
                
                if(LastKey !== 'end')
                {
                    loadUsersFromDatabase(false,SearchFilter);
                }
            }
        }
    };

    const loadUsersFromDatabase = (firstTime,filterText)=>{
        
        if(firstTime===true)
            setShowPageLoader(true);
        else
            setShowTableLoader(true);

        var numberOfItems = 20;

        if(filterText.length > 3){
            numberOfItems = 'all';
            LastKey       = 'begin';
        }

        GetUsersAPI.InvokeSync(UserInfo.LoginToken,filterText,LastKey,numberOfItems,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var Param  = JSON.parse(response['param'])
                    var Items  = Param.Items  ;

                    Logger.log('Response params ',Param); 

                    LastKey = Param.LastKey;

                    Items.forEach((user)=>{

                        var AppSettings       = {};
                        var OwnedDevices      = [];
                        var AuthorizedDevices = [];

                        AppSettings['UseMetricUnits'       ]='false';
                        AppSettings['LinkDrop'             ]='false';
                        AppSettings['VibrationConfirmation']='false';
                        AppSettings['AudibleConfirmation'  ]='false';
            
                        if(user.AppSettings === 'empty'){
                            user.AppSettings = AppSettings;
                        }else{
                            try{
                                user.AppSettings = JSON.parse(user.AppSettings);
                            }catch(e){user.AppSettings = AppSettings}
                        }
                        
                        if(user.OwnedDevices === 'empty'){
                            user.OwnedDevices = OwnedDevices;
                        }else{                            
                            try{
                                user.OwnedDevices = JSON.parse(user.OwnedDevices);
                            }catch(e){user.OwnedDevices=OwnedDevices}
                        }
                        
                        if(user.AuthorizedDevices === 'empty'){
                            user.AuthorizedDevices = AuthorizedDevices;
                        }else{                            
                            try{
                                user.AuthorizedDevices = JSON.parse(user.AuthorizedDevices);
                            }catch(e){user.AuthorizedDevices=AuthorizedDevices}
                        }                                                
                    });
                    
                    var Users = mergeUsers(Items);
                                        
                    serverItems = Users; 

                    filterUsers(Users,filterText);     
                    
                    Logger.log('Users ',Users);
                }
            }
            
            setShowPageLoader     (false);
            setShowTableLoader(false);

        })
    }


    // -------------
    // Utils
    // ------------

    const IsUserValid = (user,filter)=>{
        var valid  = false;

        var fields = ['Email','Role','UserName','OwnedDevices','AuthorizedDevices','AccessToFWA','AccessToFWB','AccessToFWI']
        
        fields.forEach( (field)=>{
            if(isUserFieldMarked(user,field,filter))
                valid = true;
        })

        return valid;
    }

    const isUserFieldMarked = (user,field,filter)=>{   
        if(field==='AccessToFWA'){
            return (isTextMarked('Alpha',filter)===true)            
        }
        if(field==='AccessToFWB'){
            return (isTextMarked('Beta',filter)===true)            
        }
        if(field==='AccessToFWI'){
            return (isTextMarked('Internal',filter)===true)            
        }

        return (isTextMarked(JSON.stringify(user[field]),filter)===true)            
    }

    const isTextMarked = (text,filter)=>{
        return (text.indexOf(filter) >= 0) ? true:false;
    }

    const markString = (text,filter)=>{
        let idx = text.indexOf(filter);
        if(idx >= 0) {
            return  [text.substring(0, idx), <strong style={{backgroundColor:'#3171b9' , color:'white'}}>{text.substring(idx, idx + filter.length)}</strong>, text.substring(idx + filter.length)];
        }
        return text;
    }

    const filterUsers = (users,filter)=>{
        var filteredUsers = [];

        users.forEach(device => {
            if(IsUserValid(device,filter)===true){
                filteredUsers.push(device);
            }
        });

        setVisibleItems(filteredUsers);

        return filteredUsers;
    }

    const isUserInList = (UserEmail)=>{
        var found = false;

        if(serverItems !== null){
            serverItems.forEach(user=>{
                if(user.Email === UserEmail){
                    found = true;
                }
            })
        }

        return found;
    }

    const mergeUsers = (NewUsers)=>{
        var newList = [];

        if(serverItems!==null){
            serverItems.forEach(user=>{
                newList.push(user);
            })
        }

        NewUsers.forEach(user=>{
            if(isUserInList(user.Email)===false){
                newList.push(user);
            }
        })

        return newList;
    }

    const updateUserRole=(user,role)=>{
        var tmp = [];

        if(serverItems != null)
        {
            serverItems.forEach((item)=>{
                if(item.Email === user.Email){
                    item.Role = role;
                }

                tmp.push(item);
            });

            serverItems = [...tmp];
        }
                
        tmp = [];

        visibleItems.forEach((item)=>{
            if(item.Email === user.Email){
                item.Role = role;
            }

            tmp.push(item);
        });

        setVisibleItems([...tmp]);        
    }
    
    // -------------
    // Render
    // -------------

    const renderTableHead = ()=>{
        return (
            <tr>
                <th className="column0 user">                 
                    <h6>#</h6>
                </th>                        
                <th className="column1 user"> 
                    <h6>Email</h6>            
                </th>
                <th className="column2 user">              
                    <h6>Name</h6>
                </th>
                <th className="column3 user">              
                    <h6>Role</h6>
                </th>                        
                <th className="column4 user">     
                    <h6>Owned devices</h6>
                </th>
                <th className="column5 user">
                    <h6>Authorized devices</h6>
                </th>
                <th className="column6 user">   
                    <h6>Firmware access</h6>
                </th>                                                                        
            </tr>
        )
    }

    const renderTableRow     = (user,index)=>{        
        var Email            = user.Email   ;
        var Role             = user.Role    ;
        var UserName         = user.UserName;
        var OwnedDevicesList = user.OwnedDevices;
        var AuthDevicesList  = user.AuthorizedDevices;
        var devices          = [];

        if(OwnedDevicesList.length===0){
            OwnedDevicesList = '---';
        }else{
            devices = user.OwnedDevices;

            OwnedDevicesList = [];

            devices.forEach(device=>{
                OwnedDevicesList.push(device.DeviceUUID);
            })
        }

        if(AuthDevicesList.length===0){
            AuthDevicesList = '---';
        }else{
            devices = user.AuthorizedDevices;

            AuthDevicesList = [];

            devices.forEach(device=>{
                var Info = device.DeviceUUID;
                AuthDevicesList.push(Info);
            })
        }

        return (
            <tr>
                <td className="column0 user" onClick={ ()=>{onUserClick(user)}}>
                    <p style={{padding:'5px'}}>{index}</p>
                </td>       

				<td className="column1 user" onClick={ ()=>{onUserClick(user)}}>
                    <p><u>{markString(Email ,SearchFilter)}</u></p>
                </td>

				<td className="column2 user" onClick={ ()=>{onUserClick(user)}}>
                    <p>{markString(UserName,SearchFilter)}</p>
                </td>

                <td className="column3 user">
                    {renderUserRole(user,Role)}
                </td>
                
                <td className="column4 user">
                    {renderDeviceList(user,OwnedDevicesList)}                    
                </td>
                
                <td className="column5 user">
                    {renderDeviceList(user,AuthDevicesList)}                    
                </td>
                
                <td className="column6 user" onClick={ ()=>{onUserClick(user)}}>
                    {renderFirmwareAccess(user)}
                </td>
                                
			</tr>
        )
    }

    const renderUserRole = (user,role)=>{

        var variant = 'dark';
        
        if(role === 'User'           ) variant = 'success'  ;
        if(role === 'Admin'          ) variant = 'danger'   ;
        if(role === 'Technical Staff') variant = 'warning'  ;
        if(role === 'Manufacturer'   ) variant = 'dark'     ;

        var admin = cookie['UserInfo' ];

        var renderDropdown = true;

        if(role === 'Admin' && admin.Role !== 'Admin' ){
            renderDropdown = false;
        }

        if(admin.Role != 'Admin'){
            renderDropdown = false;            
        }

        return(
            <div style = {{display: "flex",justifyContent: "center"}}>
                <Dropdown onClick = {()=>{
                    if(renderDropdown===false){
                        toast.error('Only an Admin user can change the role',{toastId: 0}); 
                    }
                }}>
                    <Dropdown.Toggle variant={variant} style = {{padding:'5px',width:'150px'}}>
                        {markString(role,SearchFilter)}
                    </Dropdown.Toggle>
                    
                    {renderDropdown===true && renderDropDownMenu(user)}
                    
                </Dropdown>
            </div>  
        )
    }

    
    const renderDropDownMenu = (user)=>{
        return(
            <Dropdown.Menu>
                <Dropdown.Item onClick = {()=>{onUserRoleChanged(user,'User'           )}}>User           </Dropdown.Item>                                
                <Dropdown.Item onClick = {()=>{onUserRoleChanged(user,'Admin'          )}}>Admin          </Dropdown.Item>
                <Dropdown.Item onClick = {()=>{onUserRoleChanged(user,'Technical Staff')}}>Technical Staff</Dropdown.Item>                        
                <Dropdown.Item onClick = {()=>{onUserRoleChanged(user,'Manufacturer'   )}}>Manufacturer   </Dropdown.Item>                        
            </Dropdown.Menu>
        )
    }

    const renderFirmwareAccess = (user)=>{
        const renderRelease = ()=>{
            return(
                <p style={{backgroundColor:'green',color:'white',width:'20px'}}><b>{markString('R',SearchFilter)}</b></p>                
            )
        }
        const renderBeta = ()=>{
            return(                
                <p style={{backgroundColor:'orange',color:'white',width:'20px'}}><b>{markString('B',SearchFilter)}</b></p>                
            )
        }
        const renderAlpha = ()=>{
            return(                
                <p style={{backgroundColor:'red',color:'white',width:'20px'}}><b>{markString('A',SearchFilter)}</b></p>                
            )
        }
        const renderInternal = ()=>{
            return(              
                <p style={{backgroundColor:'gray',color:'white',width:'20px'}}><b>{markString('I',SearchFilter)}</b></p>                
            )
        }

        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center"}}>                
                {renderRelease()}
                {user.AccessToFWA === 'true' && renderAlpha   ()}
                {user.AccessToFWB === 'true' && renderBeta    ()}
                {user.AccessToFWI === 'true' && renderInternal()}                              
            </div>
        )

    }

    const renderDeviceList = (user,list)=>{
        const render = (device)=>{
            return(
                <p  onClick = {()=>{onDeviceUUIDClick(device)}}>                    
                    <u>{markString(device,SearchFilter)}</u>                  
                </p>
            )
        }

        if(list!=='---'){
            return list.map((device)=>{
                return render(device);
            })
        }

        return (
            <p onClick={ ()=>{onUserClick(user)}}>---</p> 
        );
    }

    const renderTableBody = (devs)=>{
        if(Array.isArray(devs)){
            return(devs.map((d,index)=>{
                return renderTableRow(d,index);
            }));
        }else{
            return(
                <div style={{width: "100%",justifyContent: "center",paddingTop:"30px"}}>                   
                </div>
            )
        }        
    }
    
    const renderTable = ()=>{
        return (
            <div className="table100 ver4">
                <div className="table100-head">
                    <table>
                        <thead>
                            {renderTableHead()}
                        </thead>
                    </table>
                </div>
                <div className="table100-body full" ref={tableREF} onScroll={() => onScroll()}>
                    <table>
                        <tbody>
                            {renderTableBody(visibleItems)}
                        </tbody>
                    </table>
                </div>
            </div>           
        )
    }
    //max-height: calc(100vh - 350px);

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    const renderPage = ()=>{
        return (
            <>
               <Container fluid style = {{marginTop:'30px'}}>
                    {renderTable()}                    
                    {(showTableLoader===true)  && renderLoader('Grid')}
                </Container>                                
            </>
        )
    }


    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title      ='>    [ U S E R S ]' 
                                 placeholder='Search user'                                  
                                 callback={onSearchChanged}
                                 reloadCallback={reloadPage}/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showPageLoader} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminUsers;