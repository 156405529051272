import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/table.style.css'

import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

import AdminPageLoader from '../../components/AdminPageLoader';
import AdminHeader from '../../components/AdminHeader';

import AdminWizardDeviceStatusControl from '../../components/wizards/AdminWizardDeviceStatusControl';
import AdminWizardDeviceTransfer from '../../components/wizards/AdminWizardDeviceTransfer';
import AdminWizardDeviceReplace from '../../components/wizards/AdminWizardDeviceReplace';


import Loader from "react-loader-spinner";

const GetDeviceAPI = require('../../models/api/admin/GetDevices');
const GetUserInfoAPI = require('../../models/api/admin/GetUserInfo');
const Logger = require('../../models/helpers/ConsoleHelper');

var LastKey    ;
var UserInfo   ;
var serverItems;

const AdminDevices = (props) => {
    // -------------
    // State
    // -------------
    const tableREF = useRef();

    const [visibleItems  , setVisibleItems  ] = useState(null);    
    const [SearchFilter  , setSearchFilter  ] = useState('');
    const [operation     , setOperation     ] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null)


    const [showPageLoader, setShowPageLoader] = useState(true);
    const [showTableLoader, setShowTableLoader] = useState(false);
    const [cookie] = useCookies();
    const history  = useHistory();


    useEffect(() => {
        reloadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -------------
    // User Interactions
    // -------------
    const reloadPage = () => {
        UserInfo = cookie['UserInfo'];
        
        
        setSearchFilter('');
        setVisibleItems(null);
        serverItems = null;

        Logger.log('xxx',serverItems,visibleItems);

        var userInfoValid = UserInfo !== null && typeof (UserInfo) !== 'undefined';

        LastKey = 'begin';

        if (userInfoValid === true) {
            loadDevicesFromDatabase(true, '');
        } else {
            history.push("/login")
        }
    }


    const onOperationDone = () => {
        setOperation(null);
    }

    const onSearchChanged = (text) => {

        var deleteText = SearchFilter.length >= text.length;

        setSearchFilter(text);

        var filteredDevices = filterDevices(serverItems, text);

        if (deleteText === false) {
            LastKey = 'begin';
            loadDevicesFromDatabase(false, text);
        } else {
            Logger.log('Delete text')
        }
    }

    const onDeviceClick = (device) => {
        Logger.log(device);
        history.push(
            {
                pathname: "/administrator/devices/device",
                state: { device: device }
            });
    }

    const onUserUUIDClick = (userUUID) => {
        var admin = cookie['UserInfo'];

        setShowTableLoader(true);

        GetUserInfoAPI.InvokeSync(admin.LoginToken, userUUID, (response, error) => {
            setShowTableLoader(false);

            if (error !== null) {
                toast.error(error, { toastId: 0 });
            } else {
                var status = response['status'];

                if (status === 'fails') {
                    toast.error(response['error'], { toastId: 0 });
                } else {
                    var user = JSON.parse(response['param'])

                    var AppSettings = {};
                    var OwnedDevices = [];
                    var AuthorizedDevices = [];

                    AppSettings['UseMetricUnits'] = 'false';
                    AppSettings['LinkDrop'] = 'false';
                    AppSettings['VibrationConfirmation'] = 'false';
                    AppSettings['AudibleConfirmation'] = 'false';

                    if (user.AppSettings === 'empty') {
                        user.AppSettings = AppSettings;
                    } else {
                        try {
                            user.AppSettings = JSON.parse(user.AppSettings);
                        } catch (e) { user.AppSettings = AppSettings }
                    }

                    if (user.OwnedDevices === 'empty') {
                        user.OwnedDevices = OwnedDevices;
                    } else {
                        try {
                            user.OwnedDevices = JSON.parse(user.OwnedDevices);
                        } catch (e) { user.OwnedDevices = OwnedDevices }
                    }

                    if (user.AuthorizedDevices === 'empty') {
                        user.AuthorizedDevices = AuthorizedDevices;
                    } else {
                        try {
                            user.AuthorizedDevices = JSON.parse(user.AuthorizedDevices);
                        } catch (e) { user.AuthorizedDevices = AuthorizedDevices }
                    }

                    history.push({
                        pathname: "/administrator/users/user",
                        state: { user: user }
                    });

                }
            }
        });
    }

    const onScroll = () => {
        if (tableREF.current) {

            const { scrollTop, scrollHeight, clientHeight } = tableREF.current;

            if ((scrollTop + clientHeight) > (scrollHeight-10)) {

                if (LastKey !== 'end') {
                    loadDevicesFromDatabase(false, SearchFilter);
                }
            }
        }
    };

    const loadDevicesFromDatabase = (firstTime, filterText) => {

        if (firstTime === true)
            setShowPageLoader(true);
        else
            setShowTableLoader(true);

        var numberOfItems = 20;

        if(filterText.length > 3){
            numberOfItems = 'all';
            LastKey       = 'begin';
        }

        GetDeviceAPI.InvokeSync(UserInfo.LoginToken, filterText, LastKey, numberOfItems, (response, error) => {
            if (error !== null) {
                toast.error(error, { toastId: 0 });
            } else {
                var status = response['status'];

                if (status === 'fails') {
                    toast.error(response['error'], { toastId: 0 });
                } else {
                    var Param = JSON.parse(response['param'])
                    var Items = Param.Items;

                    LastKey = Param.LastKey;

                    Items.forEach((device) => {

                        var DeviceSettings = {};
                        var LinkedProdctSettings = [];
                        var AuthorizedUsers = [];

                        DeviceSettings['aux1Enable'] = 'false';
                        DeviceSettings['aux2Enable'] = 'false';
                        DeviceSettings['aux3Enable'] = 'false';
                        DeviceSettings['aux4Enable'] = 'false';
                        DeviceSettings['aux5Enable'] = 'false';
                        DeviceSettings['trunkEnable'] = 'false';

                        if (device.DeviceSettings === 'empty') {
                            device.DeviceSettings = DeviceSettings;
                        } else {
                            try {
                                device.DeviceSettings = JSON.parse(device.DeviceSettings);
                            } catch (e) { device.DeviceSettings = DeviceSettings }
                        }

                        if (device.LinkedProdctSettings === 'empty') {
                            device.LinkedProdctSettings = LinkedProdctSettings;
                        } else {
                            try {
                                device.LinkedProdctSettings = JSON.parse(device.LinkedProdctSettings);
                            } catch (e) { device.LinkedProdctSettings = LinkedProdctSettings }
                        }

                        if (device.AuthorizedUsers === 'empty') {
                            device.AuthorizedUsers = AuthorizedUsers;
                        } else {
                            try {
                                device.AuthorizedUsers = JSON.parse(device.AuthorizedUsers);
                            } catch (e) { device.AuthorizedUsers = AuthorizedUsers }
                        }
                    });

                    Logger.log('Items ',Items);

                    var Devices = mergeDevices(Items);

                    serverItems = Devices;
                    filterDevices(Devices, filterText);
                }
            }

            setShowPageLoader(false);
            setShowTableLoader(false);

        })
    }


    // -------------
    // Utils
    // ------------

    const IsDeviceValid = (device, filter) => {
        var valid = false;

        var fields = ['DeviceUUID', 'DeviceOwner', 'AuthorizedUsers']

        fields.forEach((field) => {
            if (isDeviceFieldMarked(device, field, filter))
                valid = true;
        })

        return valid;
    }

    const isDeviceFieldMarked = (device, field, filter) => {
        return (isTextMarked(JSON.stringify(device[field]), filter) === true)
    }

    const isTextMarked = (text, filter) => {
        return (text.indexOf(filter) >= 0) ? true : false;
    }

    const markString = (text, filter) => {
        let idx = text.indexOf(filter);
        if (idx >= 0) {
            return [text.substring(0, idx), <strong style={{ backgroundColor: '#3171b9', color: 'white' }}>{text.substring(idx, idx + filter.length)}</strong>, text.substring(idx + filter.length)];
        }
        return text;
    }

    const filterDevices = (devices, filter) => {
        var filteredDevices = [];

        devices.forEach(device => {
            if (IsDeviceValid(device, filter) === true) {
                filteredDevices.push(device);
            }
        });

        setVisibleItems(filteredDevices);

        return filteredDevices;
    }

    const isDeviceInList = (DeviceUUID) => {
        var found = false;

        if (serverItems !== null) {
            serverItems.forEach(device => {
                if (device.DeviceUUID === DeviceUUID) {
                    found = true;
                }
            })
        }

        return found;
    }

    const mergeDevices = (NewDevices) => {
        var newList = [];

        if (serverItems !== null) {
            serverItems.forEach(device => {
                newList.push(device);
            })
        }else{
            Logger.log('Nothing in serverItems');
        }

        NewDevices.forEach(device => {
            if (isDeviceInList(device.DeviceUUID) === false) {
                newList.push(device);
            }
        })

        Logger.log('Merged list ', newList)

        return newList;
    }

    // -------------
    // Render
    // -------------

    const renderButtonReplace = (device) => {

        if (device.DeviceOwner !== 'empty') {
            return (
                <Button variant="success"
                    style={{ width: 'auto',marginLeft:'3px' }}
                    onClick={() => {
                        setSelectedDevice(device);
                        setOperation('ShowDeviceReplace')
                    }}
                >Replace</Button>

            )
        }
    }

    const renderButtonTransfer = (device) => {

        return (
            <Button variant="warning"
                style={{ width: 'auto',marginLeft:'3px' }}
                onClick={() => {
                    setSelectedDevice(device);
                    setOperation('ShowDeviceTransfer')
                }}
            >Transfer</Button>

        )
    }

    const renderButtonDisable = (device) => {
        return (
            <Button variant="danger"
                style={{ width: 'auto' }}
                onClick={() => {
                    setSelectedDevice(device);
                    setOperation('ShowDisableDevice')
                }}
            >Disable</Button>

        )
    }

    const renderButtonEnable = (device) => {
        return (
            <Button variant="primary"
                style={{ width: 'auto',marginLeft:'3px' }}
                onClick={() => {
                    setSelectedDevice(device);
                    setOperation('ShowEnableDevice')
                }}
            >Enable</Button>

        )
    }

    const renderButtonEnableOrDisable = (device) => {
        if (device.IsActive === 'true') {
            return renderButtonDisable(device);
        }
        return renderButtonEnable(device);
    }

    const renderTableHead = () => {
        return (
            <tr>
                <th className="column0 device">
                    <h6>#</h6>
                </th>
                <th className="column1 device">
                    <h6>UUID</h6>
                </th>
                <th className="column2 device">
                    <h6>Owner</h6>
                </th>
                <th className="column3 device">
                    <h6>Authorized Users</h6>
                </th>
                <th className="column4 device">
                    <h6>Device Status</h6>
                </th>
                <th className="column5 device">                    
                </th>
                <th className="column6 device">                    
                </th>
                <th className="column7 device">                    
                </th>
            </tr>
        )
    }

    const renderTableRow = (d, index) => {

        return (
            <tr>
                <td className="column0 device" onClick={() => { onDeviceClick(d) }}>
                    <p>{index}</p>
                </td>
                <td className="column1 device" onClick={() => { onDeviceClick(d) }}>
                    <p><u>{markString(d.DeviceUUID, SearchFilter)}</u></p>
                </td>
                <td className="column2 device">
                    {renderDeviceOwnerRow(d)}
                </td>

                <td className="column3 device">
                    {renderAutorizedUserList(d)}
                </td>

                <td className="column4 device" onClick={() => { onDeviceClick(d) }}>
                    {d.IsActive === 'true' && renderInfoDeviceActive(d)}
                    {d.IsActive !== 'true' && renderInfoDeviceNotActive(d)}
                </td>
                <td className="column5 device">
                    {renderButtonEnableOrDisable(d)}
                </td>
                <td className="column6 device">
                    {renderButtonTransfer(d)}
                </td>
                <td className="column7 device">
                    {renderButtonReplace(d)}
                </td>
            </tr>
        )
    }

    const renderDeviceOwnerRow = (device) => {
        var DeviceOwner = device.DeviceOwner;

        if (DeviceOwner === 'empty') {
            return (
                <p onClick={() => { onDeviceClick(device) }}>---</p>
            )
        }

        return (
            <p onClick={() => { onUserUUIDClick(DeviceOwner) }}>
                <u>{markString(DeviceOwner, SearchFilter)}</u>
            </p>
        )


    }

    const renderAutorizedUserList = (device) => {
        var list = device.AuthorizedUsers;

        const render = (user) => {
            return (
                <p onClick={() => { onUserUUIDClick(user) }}>
                    <u>{markString(user, SearchFilter)}</u>
                </p>
            )
        }

        if (list.length !== 0) {
            return list.map((user) => {
                if (typeof (user) === 'string') {
                    return render(user);
                } else {
                    return render(user.Email);
                }

            })
        }


        return (
            <p  onClick={() => { onDeviceClick(device) }}>---</p>
        );
    }


    const renderInfoDeviceActive = (device) => {
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
                <p style={{ backgroundColor: '#3171b9', color: 'white', padding: '5px', borderRadius: '10px', width: 'auto' }}>Device enabled</p>
            </div>
        )
    }
    const renderInfoDeviceNotActive = (device) => {
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
                <p style={{ backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '10px', width: 'auto' }}>Device disabled</p>
            </div>
        )
    }

    const renderTableBody = (devs) => {
        if (Array.isArray(devs)) {
            return (devs.map((d, index) => {
                return renderTableRow(d, index);
            }));
        } else {
            return (
                <div style={{ width: "100%", justifyContent: "center", paddingTop: "30px" }}>
                </div>
            )
        }
    }


    const renderTable = () => {
        return (
            <div className="table100 ver4">
                <div className="table100-head">
                    <table>
                        <thead>
                            {renderTableHead()}
                        </thead>
                    </table>
                </div>
                <div className="table100-body full" ref={tableREF} onScroll={() => onScroll()}>
                    <table>
                        <tbody>
                            {renderTableBody(visibleItems)}
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }

    const renderLoader = (type) => {
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                <Loader type={typeof (type) === 'undefined' ? "Grid" : type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    const renderPage = () => {
        return (
            <>
                <Container fluid style={{ marginTop: '30px' }}>
                    {renderTable()}
                    {(showTableLoader === true) && renderLoader('Grid')}

                    <AdminWizardDeviceStatusControl device={selectedDevice} operation={operation} onDeviceStatusControlDone={onOperationDone} />
                    <AdminWizardDeviceTransfer device={selectedDevice} operation={operation} onDeviceTransferDone={onOperationDone} />
                    <AdminWizardDeviceReplace device={selectedDevice} operation={operation} onDeviceReplaceDone={onOperationDone} />

                </Container>
            </>
        )
    }


    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title='>    [ D E V I C E S ]' placeholder='Search device' callback={onSearchChanged} reloadCallback={reloadPage} />
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showPageLoader} render={renderPage} />
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminDevices;